import React from "react";
import AIToolsCard from "../../cards/AIToolsCard";
import AI_IMAGE1 from "../../../assets/images/interactive-interface.png";
import AI_IMAGE2 from "../../../assets/images/fundamentals.png";
import AI_IMAGE3 from "../../../assets/images/progress-interface.png";
import AI_IMAGE4 from "../../../assets/images/modules-interface.gif";
import AI_IMAGE5 from "../../../assets/images/courses-interface.gif";

const DATA = [
  {
    id: 1,
    image: AI_IMAGE1,
    name: "Interactive Learning",
    description:
      "Experience coding hands-on with fully interactive courses where you write and run code, reinforcing skills through practical challenges.",
  },
  {
    id: 2,
    image: AI_IMAGE2
    ,
    name: "Comprehensive Fundamentals",
    description:
      "Gain a solid foundation in coding with courses designed to cover all the essentials thoroughly, ensuring you build the skills you need.",
  },
  {
    id: 3,
    image: AI_IMAGE3,
    name: "Progress Tracking",
    description:
      "Monitor your progress and know exactly where you are and what’s next on your learning path.",
  },
  {
    id: 4,
    image: AI_IMAGE4,
    name: "Progressive Challenges",
    description:
      "Tackle increasingly complex coding challenges that grow with your skills, ensuring continuous improvement.",
  },
  {
    id: 5,
    image: AI_IMAGE5,
    name: "Expert-Led Content",
    description: "Learn from industry experts who break down complex topics into simple, clear explanations, helping you master coding efficiently.",
  },
];

export default function AllAIToolsSection() {
  return (
    <div>
      <div className="space-y-6 container px-4 mx-auto">
        {/* title */}
        <h1 className="display_Text text-center  ">
          Courses Designed Around Your Time
        {/* <br className="hidden md:flex " /> */}
        </h1>
        {/* sub */}
        <p className="body-custom text-center">
          We create a course schedule based on how much time you can commit each day. You’ll make progress 
          <br className="hidden md:flex " /> without disrupting your routine, no matter how busy life gets.
        </p>
        {/* tools */}
        <div className=" pt-8 md:pt-12">
          {/* use grid cal-3 */}
          <div className="lg:px-10">
            <div className="grid lg:grid-cols-3 gap-8">
              <div className="col-span-1 lg:col-span-2">
                <AIToolsCard item={DATA?.[0]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[1]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[2]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[3]} />
              </div>
              <div className="col-span-1">
                <AIToolsCard item={DATA?.[4]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
