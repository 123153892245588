import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

const Accordion = ({ faq }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full max-w-6xl mx-auto border-b border-[#0000000d]">
      <div className="rounded-md w-full">
        <button
          className="w-full py-5 flex text-black font-medium"
          onClick={toggleAccordion}
        >
          {/* header */}
          <div className="w-[35px] justify-center items-center">
            <div>
              {/* plus svg icon */}
              <AiOutlinePlus
                style={{
                  //  isOpen ? "rotate-180" : "rotate-0"
                  transform: isOpen ? "rotate(45deg)" : "rotate(180deg)",
                  transition: "transform 0.3s ease-in-out",
                  cursor: "pointer",
                }}
                size={20}
                color="#D6D6D6"
              />
            </div>
          </div>
          <h3 className="text-[16px] font-inter font-semibold text-slate-950 text-left" >{faq?.question}</h3>
        </button>
        {isOpen && (
          <div className="w-full py-1 flex ">
            <div className="w-[70px]" />
            {/* accordion content */}
            <p className="body-medium pb-4">{faq?.answer}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
