import React from "react";
import RattingCard from "../../cards/RattingCard";
import DAVID_CHEN from "../../../assets/images/David-Chen.png";
import EMILY_SANDERS from "../../../assets/images/Emily-Sanders.png";
import MIKE_THOMPSON from "../../../assets/images/Mike-Thompson.png";
import LISA_RODRIGUEZ from "../../../assets/images/Lisa-Rodriguez.png";
import SOPHIA_LEE from "../../../assets/images/Sophia-Lee.png";
import ALEXIA_PATEL from "../../../assets/images/Alexia-Patel.png";

export default function ClientSaySection() {
  return (
    <div id="testimonials">
      <div className="space-y-14 container px-4 mx-auto py-[60px]  md:py-[70px] lg:py-[80px]">
        <div className="space-y-7">
          <h1 className="display_Text text-center  ">
            Success Stories from Busy Learners
            {/* <br className="hidden md:flex " />  */}
          </h1>
          {/* sub */}
          <p className="body-custom text-center">
            Hear from professionals and students who have mastered coding on their own schedule,
            <br className="hidden md:flex " /> thanks to our interactive, expertly designed courses.
          </p>
        </div>

        {/* list */}
      </div>
      {/* client say section */}
      <div className="bg-slate-50 w-full px-2">
        {/*  */}
        <div className="space-y-14 container mx-auto py-[60px]  md:py-[70px] lg:py-[80px]">
    
          {/* list comp */}
          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3  gap-5 ">
            <RattingCard 
              author="Lisa Rodriguez"
              job="Tech Consultant"
              imageURL={LISA_RODRIGUEZ}
              content="I’ve tried a lot of coding platforms, but this one actually sticks. The content is spot-on, and I love how I can track my growth."
            />
            <RattingCard 
              author="Emily Sanders"
              job="Marketing Manager"
              imageURL={EMILY_SANDERS}
              content="Hourandcode helped me master Ruby in just a month. The way the lessons are structured really drilled the fundamentals into my brain, and I could see myself improving with every session. It’s hands down the most effective way to learn."
            />
            <RattingCard 
              author="Mike Thompson"
              job="Sales"
              imageURL={MIKE_THOMPSON}
              content="The lessons are quick, clear, and effective. I’m actually building stuff now!"
            />
            <RattingCard 
              author="Sophia Lee"
              job="Full-time Student"
              imageURL={SOPHIA_LEE}
              content="This platform made learning to code feel doable. The structured paths keep me focused, and the progress tracking is the best. It’s like they built it just for people like me."
            />
            <RattingCard 
              author="David Chen"
              job="Project Manager"
              imageURL={DAVID_CHEN}
              content="Hourandcode made coding approachable and fun. I can fit in a lesson between meetings, and I’m not just learning—I’m applying it immediately. Total win."
            />
            <RattingCard 
              author="Alexia Patel"
              job="Software Developer"
              imageURL={ALEXIA_PATEL}
              content="As someone who juggles a full-time job and side projects, finding time to code was tough. Hourandcode made it simple, with lessons I can squeeze in anywhere. Now I’m coding daily!"
            />
          </div>
          {/* list comp */}
        </div>
      </div>
    </div>
  );
}
