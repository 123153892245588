import React from "react";
import According from "../../according/According";

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "What is Hourandcode?",
    answer:
      "Hourandcode is a flexible online learning platform designed for busy professionals and students who want to master coding without sacrificing their daily responsibilities.",
  },
  {
    question: "How does Hourandcode work?",
    answer:
      "Hourandcode offers self-paced courses that fit into your schedule. The platform allows you to set your learning pace, choose between different course views, and engage in interactive lessons where you write and run code.",
  },
  {
    question: "What courses does Hourandcode offer?",
    answer:
    "Hourandcode offers interactive coding courses that cover Python, Rust, Golang, Ruby and more. You can view the full course list on the Courses page: https://app.hourandcode.com/courses",
},
  {
    question: "Is my progress tracked with Hourandcode?",
    answer:
    "Yes, Hourandcode includes detailed progress tracking. You can easily monitor your achievements and see what’s next on your learning path, keeping you motivated and on track.",
},
  {
    question: "Can I learn on the go with Hourandcode?",
    answer:
      "Absolutely! Hourandcode is mobile-optimized, so you can access your courses from anywhere, making it easy to fit learning into your busy schedule.",
  },
];

export default function FAQSection() {
  return (
    <div id="faq">
      <div className="space-y-14 container px-4 mx-auto pb-[50px] md:pb-[100px]  lg:pb-[130px]">
        <div className="space-y-7">
          <h1 className="display_Text text-center ">FAQ</h1>
          <p className="body-custom text-center ">Frequently Asked Questions</p>
        </div>
        {/* con */}
        <div className="max-w-2xl mx-auto my-10">
          {faqs.map((faq, index) => {
            return (
              <>
                <According key={index} faq={faq} />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
