/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BG_IMAGE from "../../../assets/bg/hero-bg.svg";
import LESSON_IMAGE from "../../../assets/images/lesson-interface.png";
import AI_ICON from "../../../assets/icons/ai-icon.svg";
// import PLAY_ICON from "../../../assets/icons/play-icon.svg";
import ARROW_RIGHT_TOP_ICON from "../../../assets/icons/arrow-right-top-icon.svg";
import TrustedByFounders from "./TrustedByFounders";
import PreLaunchOffer from "./PreLaunchOffer";
import { motion } from "framer-motion";

export default function HeroSection() {
  return (
    <section
      style={{
        backgroundImage: `url(${BG_IMAGE})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="min-h-screen px-2 flex justify-center items-center pb-7 pt-32  md:pt-40"
    >
      <div className=" space-y-16 md:space-y-20">
        {/* top */}
        <div className="max-w-[748px] space-y-7 mx-auto">
          {/* box */}
          <div className="flex justify-center pt-1 pb-5">

            <div className="flex items-center gap-3 bg-white shadow-sm shadow-gray-200 py-1 px-2 rounded-full border  ">
              <div className="flex items-center gap-1">
                <img
                  src={AI_ICON}
                  alt="AI icon"
                  className="w-[16px] h-[16px]"
                />
                <p className="caption">Mastering Python Series is now live</p>
              </div>
              <a href="https://app.hourandcode.com/courses/pythonPart1" className="caption-link flex items-center gap-1 ">
                Try it
                <img
                  src={ARROW_RIGHT_TOP_ICON}
                  alt="AI icon"
                  className="w-[16px] h-[16px]"
                />
              </a>
            </div>
          </div>
          {/* <div className="flex items-center justify-center" >
            <PreLaunchOffer />
          </div> */}
          {/* title */}
          <h1 className="display_Text text-center  ">
            Learn to Code with Minimal Daily Commitment.
            <br className="hidden md:flex " />
          </h1>
          {/* sub */}
          <p className="body-custom text-center">
          Gain job-ready coding skills in as little as 1 hour a day, with guaranteed mastery or personalized support until you succeed.
            <br className="hidden md:flex " />
          </p>
          {/* buttons */}
          <div className="flex justify-center gap-4 md:pt-6">
            {/* <a href="#" className="flex">
              <div className="button-outlined gap-2">
                <img
                  src={PLAY_ICON}
                  alt="AI icon"
                  className="w-[20px] h-[20px]"
                />
                See how it works
              </div>
            </a> */}
            <a href="https://app.hourandcode.com" className="flex">
              <div className="button shimmering-button">
                Start for Free
              </div>
            </a>
          </div>
          <p className="flex items-center justify-center">No credit card needed to start.</p>
          {/* TrustedByFounders */}
          <div className="flex items-center justify-center">
            <TrustedByFounders />
          </div>
        </div>
        {/* image */}
        <div className="flex justify-center max-w-[1104px] bg-[#F9FAFB] p-2 md:p-7 rounded-md shadow-lg md:shadow-sm">
          <img
            src={LESSON_IMAGE}
            alt="Hourandcode Lesson"
            className="w-full h-full rounded-lg shadow-sm"
          />
        </div>
      </div>
    </section>
  );
}
