import React from "react";
import Navbar from "./layouts/Navbar";
import Footer from "./layouts/Footer";
import HeroSection from "./components/core/home/HeroSection";
// import FastGrowingStartups from "./components/core/home/FastGrowingStartups";
import AllAIToolsSection from "./components/core/home/AllAIToolsSection";
import DiscoverUnlimited from "./components/core/home/DiscoverUnlimited";
import ClientSaySection from "./components/core/home/ClientSaySection";
import PricingPlansSection from "./components/core/home/PricingPlansSection";
import FAQSection from "./components/core/home/FAQSection";
import NextOpportunity from "./components/core/home/NextOpportunity";

function App() {
  return (
    <div className="bg-white">
      <Navbar />
      {/* components st */}
      <HeroSection />
      {/* <FastGrowingStartups /> */}
      <AllAIToolsSection />
      <DiscoverUnlimited />
      <ClientSaySection />
      {/* <PricingPlansSection /> */}
      <FAQSection />
      <NextOpportunity/>
      {/* components end */}
      <Footer />
    </div>
  );
}

export default App;
