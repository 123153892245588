import React from "react";
import MAP_IMAGE from "../../../assets/images/woman-coding.webp";

export default function NextOpportunity() {
  return (
    <div className="bg-[#F9FAFC] py-[40px] md:py-[50px]  lg:py-[80px] px-4 lg:px-2 ">
      <div className="container mx-auto border shadow-sm rounded-3xl bg-white relative h-[450px] sm:h-[510px] md:h-[510px] lg:h-[610px] overflow-hidden ">
        <div className="grid md:grid-cols-2 h-full">
          {/* section 1 */}
          <div className="max-w-[470px] flex flex-col justify-center px-4 md:px-8 space-y-4  md:space-y-8 ">
            <h2 className="heading2">Your Next Big Opportunity Starts Here</h2>
            <h4 className="body-custom">
                Gain the coding skills you need to turn your ideas into reality. Don’t wait—start now and see what you can create.
            </h4>
            <div>
              {/* <a href="https://app.hourandcode.com" className="button-outlined">Start Now</a> */}
              <a href="https://app.hourandcode.com" className="button">Start Now</a>
            </div>
          </div>
          {/* world  */}
          <div className="flex justify-center items-end h-full ">
            <img
              // className="absolute w-[400px] md:w-[550px] lg:w-[750px]  xl:w-[850px] top-[58%] sm:top-[48%]  md:top-[14%]  lg:top-[10%] -right-[28%] md:-right-[20%] lg:-right-[15%] "
              src={MAP_IMAGE}
              alt="world"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
