/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaTwitter, FaFacebook, FaYoutube } from "react-icons/fa6";
export default function Footer() {
  return (
    <footer className="py-3">
      <div className="max-w-7xl mx-auto px-4">
        <div className="lg:flex flex-wrap justify-between space-y-5 md:space-y-10 xl:space-y-0">
          {/* Left Column */}
          {/* <div className="mb-8 sm:mb-0">
            <p className="text-[24px] font-sans font-[600] text-slate-950 tracking-tight mb-6 ">
              Hourandcode
            </p>
            <div className="w">
              <div className="flex mb-4 bg-[#F9FAFB] rounded-full p-1 justify-between w-full lg:max-w-[300px] ">
                <input
                  type="email"
                  className="pl-2 rounded-l-md focus:outline-none  bg-transparent text-slate-800 placeholder-gray-400 w-full sm:w-60"
                  placeholder="name@email.com"
                />
                <button
                  type="submit"
                  className="bg-[#363A44] text-white rounded-full px-4 py-2"
                >
                  Subscribe
                </button>
              </div>
            </div>
            <p className="font-inter  text-[12px] lg:text-[14px]   text-[#292F38]">
              No Spam. Just Product updates.
            </p>
          </div> */}
          {/* Right Columns */}
          <div className="w-full lg:flex  flex-wrap xl:justify-end lg:space-x-20 xl:space-x-28">
            {/* Product */}
            <div className=" mb-6">
              <h4 className="text-[16px] font-normal text-slate-950 mb-2">
                Product
              </h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Courses
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Faq
                  </a>
                </li>
              </ul>
            </div>
            {/* Courses */}
            <div className=" mb-6">
              <h4 className="text-[16px] font-normal text-slate-950 mb-2">
                Courses
              </h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Learn to Code at Your Own Pace
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Beginner Coding Courses
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Advanced Coding Lessons
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Interactive Coding Challenges
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Coding Courses for Busy Professionals
                  </a>
                </li>
              </ul>
            </div>
            {/* Resources */}
            <div className="">
              <h4 className="text-[16px] font-normal text-slate-950 mb-2">
                Resources
              </h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    What to Do After the Hour of Code
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Self-Paced Coding for Professionals
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Best Coding Tutorials for Beginners
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Introduction to Programming Languages
                  </a>
                </li>
              </ul>
            </div>
            {/* Support */}
            <div className=" mb-6">
              <h4 className="text-[16px] font-normal text-slate-950 mb-2">
                Support
              </h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Help Desk
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Public roadmap
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Discord
                  </a>
                </li>
              </ul>
            </div>
            {/* Company */}
            <div className="">
              <h4 className="text-[16px] font-normal text-slate-950 mb-2">
                Company
              </h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[14px] text-[#475569] hover:text-slate-950"
                  >
                    Terms of use
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* footer section */}
        <div className="flex flex-col lg:flex-row gap-5 lg:gap-0 justify-between items-center ">
          <p className="mt-7 md:mt-4 font-inter text-[12px] xl:text-[14px] lg:text-[15px] text-[#292F38]">
            Hourandcode. All rights reserved. © 2024
          </p>
          <div className="flex space-x-4 items-center ">
            <FaTwitter
              color="#8A929C"
              className="text-[18px] md:text-[20px] lg:text-[24px]"
            />
            <FaFacebook
              color="#8A929C"
              className="text-[18px] md:text-[20px] lg:text-[24px]"
            />
            <FaYoutube
              color="#8A929C"
              className="text-[18px] md:text-[20px] lg:text-[24px]"
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
