import React, { useState } from "react";
import LOGO_IMG from "../assets/images/Hourandcode-LOGO.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu open/close
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="z-20 py-4 lg:py-3 px-4 lg:px-6 shadow-md shadow-gray-50 border-b block lg:flex justify-center fixed w-full backdrop-blur-lg bg-white/10">
      <nav className="flex items-center justify-between container mx-auto">
        {/* left nav list */}
        <div className="flex items-center gap-12 justify-center">
          <p className="text-[24px] font-inter font-[600] text-slate-950 tracking-tight" style={{color: "#0d6efd"}}>
            <img src={LOGO_IMG} style={{width: '220px'}} alt="Hourandcode LOGO"/>
          </p>
          <div className="hidden md:flex space-x-5 text-gray-500">
            <a href="https://app.hourandcode.com/courses" className="nav-a">
              Courses
            </a>
            <a href="#faq" className="nav-a">
              FAQ
            </a>
          </div>
        </div>

        {/* right button */}
        <div className="hidden md:flex space-x-5 items-center">
          <a href="https://app.hourandcode.com/authentication" className="text-[14px] text-[#666666] font-light ">
            Sign in
          </a>
          <a href="https://app.hourandcode.com" className="button md:text-md md:px-3.5 md:py-1 tracking-[0.040em] ">
            Start Learning
          </a>
        </div>

        {/* menu button with animation */}
        <div className="flex md:hidden items-center gap-3">
          <button
            className="text-[16px] text-gray-600 font-medium transition-transform duration-300"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 transition-transform duration-300 transform ${
                  isMenuOpen ? "rotate-90" : "rotate-0"
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 20L20 6M6 6l14 14"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 transition-transform duration-300 transform ${
                  isMenuOpen ? "rotate-0" : "rotate-0"
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h20M4 18h20"
                />
              </svg>
            )}
          </button>
        </div>
      </nav>
      {/* menu mobile */}
      <div
        className={`flex md:hidden  w-full transition-all duration-500 ease-in-out overflow-hidden z-20  ${
          isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="w-full min-h-screen flex flex-col items-center py-10 space-y-5">
          <div className="flex flex-col items-center space-y-5 text-gray-500">
            <a href="https://app.hourandcode.com/courses" className="nav-a">
              Courses
            </a>
            <a href="#faq" className="nav-a">
              FAQ
            </a>
            <a href="#testimonials" className="nav-a">
              Testimonials
            </a>
          </div>
          {/* buttons */}
          <a href="https://app.hourandcode.com/authentication" className=" text-[16px] lg:text-[14px] text-[#666666] font-light">
            Sign in
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
