import React from "react";
interface IProps {
  item: {
    id: number;
    image: string;
    name: string;
    description: string;
  };
}

export default function AIToolsCard({ item }: IProps) {
  return (
    <div className="block items-center bg-[#F8FAFC] p-3 md:p-4 border rounded-2xl lg:h-[370px] ">
      <img
        src={item?.image}
        alt="AI tool"
        className={`w-full  md:h-[230px] lg:h-[220px] rounded-md ${
          item?.id === 1 ? "" : "object-contain"
        } `}
      />
      <div className="pt-5 space-y-2">
        <h3 className="heading4">{item?.name}</h3>
        <p className="body-small lg:body-medium max-w-[400px]">
          {item?.description}
        </p>
      </div>
    </div>
  );
}
