import React from "react";

const RattingCard = ({ author, job, content, imageURL }: { author: string; job: string; content: string, imageURL: string }) => {
  return (
    <div className=" w-full lg:max-w-md p-6 my-1 bg-white border border-gray-200 rounded-xl shadow-sm">
      <div className="flex items-center mb-4">
        {/* Star Ratings */}
        <div className="flex">
          <svg fill="#F4A142" viewBox="0 0 24 24" className="w-5 h-5">
            <path d="M12 2.75l2.753 5.649 6.239.906-4.51 4.396 1.064 6.208L12 16.523l-5.547 2.913 1.065-6.207-4.51-4.397 6.239-.906L12 2.75z" />
          </svg>
          <svg fill="#F4A142" viewBox="0 0 24 24" className="w-5 h-5">
            <path d="M12 2.75l2.753 5.649 6.239.906-4.51 4.396 1.064 6.208L12 16.523l-5.547 2.913 1.065-6.207-4.51-4.397 6.239-.906L12 2.75z" />
          </svg>
          <svg fill="#F4A142" viewBox="0 0 24 24" className="w-5 h-5">
            <path d="M12 2.75l2.753 5.649 6.239.906-4.51 4.396 1.064 6.208L12 16.523l-5.547 2.913 1.065-6.207-4.51-4.397 6.239-.906L12 2.75z" />
          </svg>
          <svg fill="#F4A142" viewBox="0 0 24 24" className="w-5 h-5">
            <path d="M12 2.75l2.753 5.649 6.239.906-4.51 4.396 1.064 6.208L12 16.523l-5.547 2.913 1.065-6.207-4.51-4.397 6.239-.906L12 2.75z" />
          </svg>
          <svg fill="#F4A142" viewBox="0 0 24 24" className="w-5 h-5">
            <path d="M12 2.75l2.753 5.649 6.239.906-4.51 4.396 1.064 6.208L12 16.523l-5.547 2.913 1.065-6.207-4.51-4.397 6.239-.906L12 2.75z" />
          </svg>
          {/* <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="#F4A142"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 2.75l2.753 5.649 6.239.906-4.51 4.396 1.064 6.208L12 16.523l-5.547 2.913 1.065-6.207-4.51-4.397 6.239-.906L12 2.75z"
            />
          </svg> */}
        </div>
      </div>

      {/* Review Text */}
      <p className="mb-6 text-gray-700">
        {content}
      </p>

      {/* User Info */}
      <div className="flex items-center">
        <img
          className="w-[54px] h-[54px] rounded-full"
          src={imageURL}
          alt={author}
        />
        <div className="ml-4 h-[54px] ">
          <h5 className="text-[16px] font-sans font-normal text-gray-900">{author}</h5>
          <span className="text-[16px] font-sans font-normal text-gray-500">{job}</span>
        </div>
      </div>
    </div>
  );
};

export default RattingCard;
