import React from "react";
import COURSE_IMAGE from "../../../assets/images/course-interface.png";
import AI_ICON from "../../../assets/icons/ai-icon.svg";
import ROCKET_ICON from "../../../assets/icons/rocket-icon.svg";
import TYPING_ICON from "../../../assets/icons/typing.png";

// Get instant answers
const DATA_LIST = [
  {
    id: 1,
    title: "Set Your Own Pace",
    description:
      "Choose how much time you spend learning each day, from just an hour to more, fitting your schedule perfectly.",
    image: AI_ICON,
  },
  {
    id: 2,
    title: "Personalized Learning Paths",
    description:
      "Easily switch between a view that fits your daily routine and a structured layout by modules.",
    image: ROCKET_ICON,
  },
  {
    id: 3,
    title: "Learn by Doing",
    description:
      "Engage in interactive lessons where you execute code and solve real challenges directly within the course.",
    image: TYPING_ICON,
  },
];

export default function DiscoverUnlimited() {
  return (
    <div>
      <div className="space-y-14 container px-4 mx-auto py-[60px]  md:py-[70px] lg:py-[80px]">
        <div className="space-y-7">
          <h1 className="display_Text text-center ">
            Learn by Doing, Not Just Watching
          </h1>
          <p className="body-custom text-center ">
            Jump into coding with interactive lessons and immediate feedback. Write and run real code,
            <br className="hidden md:flex" /> and master skills through hands-on experience with every lesson.
          </p>
        </div>
        <div className="flex justify-center max-w-[1104px] bg-[#F9FAFB] p-2 md:p-7 rounded-md shadow-lg md:shadow-sm mx-auto">
          <img
            src={COURSE_IMAGE}
            alt="chatgpt"
            className="w-full h-full rounded-lg shadow-sm"
          />
        </div>
        {/* list */}
        <div className="grid lg:grid-cols-3 gap-8">
          {DATA_LIST.map((item) => (
            <div
              key={item.id}
              className="flex flex-col gap-3 lg:gap-5 items-center  p-6"
            >
              <div className="w-[48px] h-[48px] bg-slate-100 flex items-center justify-center rounded-md ">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-[24px] h-[24px] "
                />
              </div>
              <p className="heading4 text-center">{item.title}</p>
              <p className="body-small lg:body-medium text-center">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
